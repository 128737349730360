// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
svg.herosvg [fill]:not([fill="none"]) {
    fill: #eee
}

.blob.blob-6 {
    -webkit-mask-image: url("../img/blobs/blob-6.svg");
            mask-image: url("../img/blobs/blob-6.svg");
        
}

.blob.blob-7 {
    -webkit-mask-image: url("../img/blobs/blob-7.svg");
            mask-image: url("../img/blobs/blob-7.svg");
        
}